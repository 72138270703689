<template>
  <div class="real-time-clock">
    <div class="real-time-clock-content">
      <div class="real-time-clock-content-time">
        <span>{{time}}</span>
        <span class="ml-1">{{week}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, reactive, toRefs} from "@vue/composition-api";

export default {
  name: "RealTimeClock",
  setup(){
    const event = reactive({
      time: "0000-00-00 00:00:00",
      week: "星期一"
    })
    onMounted(() => {
      setInterval(() => {
        const date = new Date()
        event.time = date.toLocaleString().replaceAll('/', '-')
        event.week = "星期" + "日一二三四五六".charAt(date.getDay())
      }, 1000)
    })
    return {
      ...toRefs(event)
    }
  }
}
</script>

<style scoped>

</style>